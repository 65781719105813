<template lang="html">
  <div>
    <OxideMineralInput
      v-model="phosphorus" :oxide.sync="useOxideForm.phosphorus"
      nutrient="phosphorus" :original-data="originalMeasurements.phosphorus"
      :readonly-timestamp="useUniformTimestamp"
      :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.PhosphorusTitle')"
      unit="mg/100g"
    >
      <SimpleSwitch v-model="probingMethod">
        DL
        <template #offLabel>
          CAL
        </template>
      </SimpleSwitch>
    </OxideMineralInput>

    <OxideMineralInput
      v-model="potassium" :oxide.sync="useOxideForm.potassium"
      nutrient="potassium" :original-data="originalMeasurements.potassium"
      :readonly-timestamp="useUniformTimestamp"
      :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.PotassiumTitle')"
      unit="mg/100g"
    />

    <OxideMineralInput
      v-model="magnesium" :oxide.sync="useOxideForm.magnesium"
      nutrient="magnesium" :original-data="originalMeasurements.magnesium"
      :readonly-timestamp="useUniformTimestamp"
      :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.MagnesiumTitle')"
      unit="mg/100g"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import SimpleSwitch from '@components/forms/SimpleSwitch'

import OxideMineralInput from './OxideMineralInput'

import OriginalMeasurementsMixin from './OriginalMeasurementsMixin'

export default {
  components: {
    SimpleSwitch,
    OxideMineralInput
  },
  mixins: [
    OriginalMeasurementsMixin
  ],
  data () {
    return {
      useOxideForm: {
        potassium: false,
        phosphorus: false,
        magnesium: false
      }
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/nutritionStatus', [
      'measurements.potassium',
      'measurements.phosphorus',
      'measurements.magnesium'
    ]),
    // other nutrient properties
    ...mapFormFields('fieldRecordSystem/nutritionStatus', {
      'measurements.phosphorus.probingMethod': {
        read: value => value === 'DL',
        write: value => value ? 'DL' : 'CAL'
      }
    }),
    ...mapState('fieldRecordSystem/nutritionStatus', [
      'useUniformTimestamp'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
