<template lang="html">
  <div class="nutrient">
    <div class="form-group">
      <slot name="input">
        <NumericInput
          :value="value.value"
          :name="`${name}_value`"
          :placeholder="originalData && originalData.value ? $i18n.format(originalData.value) : ''"
          v-bind="$attrs"
          @input="$emit('input', {...value, value: $event})"
        >
          <template #label>
            <slot><IxRes>Common.SR_Common.Measurement</IxRes></slot>
          </template>
        </NumericInput>
      </slot>
    </div>

    <div class="form-group">
      <VueDateTimePicker
        :value="value.timestamp"
        :placeholder="originalData && originalData.timestamp ? $i18n.format(originalData.timestamp, 'datetime') : ''"
        format="L LT" iso
        :readonly="readonlyTimestamp" :required="value.value !== null"
        :name="`${name}_timestamp`"
        @input="$emit('input', {...value, timestamp: $event})"
      >
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.Date</IxRes>
        </template>
      </VueDateTimePicker>
    </div>
  </div>
</template>

<script>
import NumericInput from '@components/forms/NumericInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'

export default {
  components: {
    NumericInput,
    VueDateTimePicker
  },
  props: {
    value: Object, // {value, timestamp, ...}
    name: String,
    readonlyTimestamp: Boolean,
    originalData: Object // {value, timestamp, ...}
  }
}
</script>

<style lang="scss" scoped>
.nutrient {
  display: flex;
  .form-group {
    flex: 1;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
