<template lang="html">
  <div>
    <h4>
      <IxRes>geoMeasurements.headers.miscMeasurements</IxRes>
    </h4>

    <NutrientMeasurementInput
      v-model="humusContent" :original-data="originalMeasurements.humusContent"
      name="humusContent" :readonly-timestamp="useUniformTimestamp"
      :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.HumusContentTitle')"
      :rule="between(0, 50)"
      percent percent-not-normalized
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.HumusContent</IxRes>
    </NutrientMeasurementInput>

    <NutrientMeasurementInput
      v-model="waterLevel" :original-data="originalMeasurements.waterLevel"
      name="waterLevel" :readonly-timestamp="useUniformTimestamp"
      :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterLevelTitle')"
      integer :rule="between(0, 1000)"
      unit="mm"
    >
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilMoisture</IxRes>
    </NutrientMeasurementInput>

    <NutrientMeasurementInput
      v-model="ph" :original-data="originalMeasurements.ph"
      name="pH" :readonly-timestamp="useUniformTimestamp"
      :rule="between(0, 14)"
    >
      pH
    </NutrientMeasurementInput>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import NutrientMeasurementInput from './NutrientMeasurementInput'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import OriginalMeasurementsMixin from './OriginalMeasurementsMixin'

export default {
  components: {
    NutrientMeasurementInput
  },
  mixins: [
    StandardRulesMixin,
    OriginalMeasurementsMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/nutritionStatus', [
      'measurements.humusContent',
      'measurements.waterLevel',
      'measurements.ph'
    ]),
    ...mapState('fieldRecordSystem/nutritionStatus', [
      'useUniformTimestamp'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
