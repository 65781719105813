<template>
  <div>
    <FabButton v-bind="{text, icon, locked, hoverText}" :expanded="expanded" @click="toggle">
      <slot>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ text }}</IxRes>
        <i class="fa pull-right" :class="[expanded !== (direction === 'up') ? 'fa-caret-up' : 'fa-caret-down']" />
      </slot>
    </FabButton>
    <transition :name="direction === 'up' ? 'menu-expand-up' : 'menu-expand-down'">
      <div v-if="expanded" :class="direction === 'up' ? 'fab-menu-top' : 'fab-menu-bottom'">
        <div v-for="(item, i) in actions" :key="i" class="buttonspacer">
          <FabButton v-if="!item.hide" v-bind="item" @click="$emit('click', item)" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FabButton from './FabButton'

export default {
  components: {
    FabButton
  },
  props: {
    actions: Array,
    text: String,
    direction: {type: String, default: 'down'}, // 'up' or 'down'
    icon: String,
    locked: Boolean,
    expanded: Boolean,
    hoverText: String
  },
  methods: {
    toggle () {
      if (!this.locked) {
        this.$emit('toggle')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-expand-up-enter-active, .menu-expand-up-leave-active,
.menu-expand-down-enter-active, .menu-expand-down-leave-active {
  transition: opacity .25s, transform .25s;
}

.menu-expand-up-enter, .menu-expand-up-leave-to {
  opacity: 0;
  transform: translateY(7px);
}

.menu-expand-down-enter, .menu-expand-down-leave-to {
  opacity: 0;
  transform: translateY(-7px);
}

.fab-menu-top {
  position: absolute;
  bottom: 30px;
}

.fab-menu-bottom {
  position: absolute;
  top: 30px;
}

.buttonspacer {
  margin-bottom: 3px;
}
</style>
