<template lang="html">
  <div class="weather-container">
    <WeatherIcon
      v-if="currentWeather"
      :total-cloud-cover="currentWeather.totalCloudCover" :precipitation="currentWeather.precipitation"
      :temperature="currentWeather.temperature"
      :size="96"
    />
    <HelpBox v-else type="warning">
      <IxRes>frs.dashboards.field.hints.noWeatherData</IxRes>
    </HelpBox>
  </div>
</template>

<script>
import moment from 'moment'

import HelpBox from '@components/help/HelpBox'
import WeatherIcon from './WeatherIcon'

export default {
  components: {
    HelpBox,
    WeatherIcon
  },
  props: {
    climate: Object
  },
  computed: {
    currentWeather () {
      const date = moment()
      const currentDate = `${date.year()}-0${date.month() + 1}-${date.date()}`
      const currentHour = date.hour()

      if (this.climate && this.climate[currentDate]) {
        return this.climate[currentDate][currentHour]
      } else {
        return null
      }
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('ddd')
    }
  }
}
</script>

<style lang="scss" scoped>
.weather-container, .weather-container-today, .weather-container-forecast, .weather-icon, .weather-details-today {
  display: flex;
}
</style>
