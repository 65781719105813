<template>
  <div class="map-action-control">
    <FloatingActionButton>
      <div v-for="(item, i) in menuData" :key="i" :slot="slotOrder[i]">
        <FabSubMenu
          v-if="i !== 2 && i !== 3 && item.visible && item.actions"
          v-bind="item"
          :direction="i < 3 ? 'up' : 'down'"
          :expanded="expandedIndex === i"
          @click="onClick"
          @toggle="expandedIndex = expandedIndex === i ? null : i"
        />
        <FabButton
          v-else-if="item.visible && !item.actions"
          v-bind="item"
          @click="onClick(item)"
        />
      </div>
    </FloatingActionButton>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {smarterGet} from '@helpers/vuex/data-loading'
import {can} from '@helpers/permissions'

import FloatingActionButton from './FloatingActionButton'
import FabSubMenu from './FabSubMenu'
import FabButton from './FabButton'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    FloatingActionButton,
    FabSubMenu,
    FabButton
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      expandedIndex: null,
      closeMenuOnClick: false,
      cropUsagesByFieldId: {},
      hasCorrectCropForCurrentHarvestYear: false
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.permissions
    }),
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field,
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapState('masterData', [
      'crops',
      'cropUsages'
    ]),
    ...mapState('fieldRecordSystem/navigation', {
      orgUnitId: state => state.location.orgUnitId,
      selectedFieldId: state => state.location.fieldId
    }),
    hasMaizeSowingModuleAccess () {
      return this.$can('Use.Frs.MaizeSowing')
    },
    canUseMaizeSowingModule () {
      return this.hasMaizeSowingModuleAccess && this.hasCorrectCropForCurrentHarvestYear
    },
    canUsePhBbModule () {
      const fieldUsage = this.fieldData[this.selectedFieldId].fieldUsage
      const validFieldUsages = ['arable', 'grass']
      return validFieldUsages.includes(fieldUsage)
    },
    maizeSowingHoverText () {
      return this.hasMaizeSowingModuleAccess && !this.hasCorrectCropForCurrentHarvestYear
        ? this.$i18n.translate('frs.fabButtons.hoverText.wrongCultivation')
        : null
    },
    pHBbHoverText () {
      return !this.canUsePhBbModule
        ? this.$i18n.translate('frs.fabButtons.hoverText.phBB.wrongFieldUsage')
        : null
    },
    menuData () {
      // the menu assumes always having 6 items
      let menuOptions = [
        {
          text: 'PrecisionFarmingModules',
          visible: true,
          actions: [
            {
              text: 'BiomassMaps',
              hide: !this.$can('feature/module-biomass-maps'),
              locked: !this.$can('Create.Frs.BiomassMap'),
              route: {name: 'biomassMaps'}
            },
            {
              text: 'PhBb',
              route: {name: 'phbb'},
              hide: !this.$can('feature/module-phbb'),
              locked: !this.canUsePhBbModule,
              hoverText: this.pHBbHoverText
            },
            {
              text: 'ZoneMapCreation',
              route: {name: 'manualZoneMaps'},
              hide: !this.$can('Create.Frs.ZoneMap')
            },
            {
              text: 'AutomaticZoneMaps',
              route: {name: 'automaticZoneMaps'},
              locked: !this.$can('Create.Frs.AutomaticZoneMap')
            },
            {
              text: 'SimpleApplicationMaps',
              route: {name: 'simpleApplicationMaps'},
              locked: !this.$can('Use.Frs.SimpleApplicationMaps')
            },
            {
              text: 'MS_MaizeSowing_button',
              action: 'maizeSowing/startMaizeSowing',
              locked: !this.canUseMaizeSowingModule,
              hoverText: this.maizeSowingHoverText
            },
            {
              text: 'AlgorithmicApplicationMapCreation',
              action: 'applicationMaps/startCreation',
              hide: !this.$can('Use.Frs.FertAssist') && !this.$can('Use.Frs.SeedExpert')
            }
          ]
        },
        {
          text: 'GeoTraxLanePlanning',
          visible: true,
          actions: [
            {
              text: 'NewLanePlan',
              workflow: 'lanePlanning',
              icon: 'plus'
            },
            {
              text: 'ViewLanePlans',
              workflow: 'lanePlanSelectionOrgUnit'
            }
          ],
          locked: !this.$can('Use.Geotrax')
        },
        // DBE is deactivated since it is currently not working anyway
        {
          text: 'NutrientRequirements',
          visible: this.$can('feature/module-nutrient-requirement'),
          route: {name: 'nutrientRequirements'},
          locked: !this.$can('Use.Frs.NutrientRequirementCalculation')
        },
        {
          text: 'EditGeometry',
          visible: true,
          action: 'startEditingField'
        },
        {
          text: 'CultivationPlan',
          visible: true,
          actions: [
            {
              text: 'NewCultivation',
              method: 'startPlanning',
              icon: 'plus'
            },
            {
              text: 'ViewCultivations',
              route: {name: 'cultivations', params: {year: this.harvestYear, orgUnitId: this.orgUnitId}, query: {selectedFieldId: this.selectedFieldId}}
            }
          ],
          locked: !this.$can('Use.Frs.Cultivations')
        },
        {
          text: 'Actions',
          visible: this.$can('feature/module-actions'),
          actions: [
            {
              text: 'NewAction',
              action: 'action/startCreatingActionFromField',
              icon: 'plus'
            },
            {
              text: 'ViewActions',
              action: 'displayActionsForField'
            }
          ],
          locked: !this.$can('Use.Frs.Actions')
        }
      ]

      return menuOptions
    },
    slotOrder () {
      return ['top-left', 'top-right', 'left', 'right', 'bottom-left', 'bottom-right']
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'setFabCollapsed',
      'cultivationPlanningOrgUnit'
    ]),
    startPlanning () {
      // this.$router.push({
      //   name: 'orgDashboard',
      //   params: {
      //     year: this.harvestYear,
      //     orgUnitId: this.orgUnitId}
      // })
      this.cultivationPlanningOrgUnit({fieldId: this.selectedFieldId})
    },
    onClick (item) {
      if (item.action) {
        this.$store.dispatch('fieldRecordSystem/' + item.action)
      }
      if (item.workflow) {
        this.$store.commit('fieldRecordSystem/setRightView', item.workflow)
      }
      if (item.route) {
        this.$router.push({...item.route})
        this.$store.commit('fieldRecordSystem/setRightView', 'default')
      }
      if (item.method) {
        this[item.method]()
      }
      if (this.closeMenuOnClick) {
        this.expandedIndex = null
        this.setFabCollapsed(true)
      }
    },
    calculateCorrectCropForCurrentHarvestYear () {
      const fieldId = this.selectedFieldId
      if (!fieldId) return false

      const cultivations = this.cultivationsByFieldId[fieldId]
      if (!cultivations) return false

      return cultivations.some(cultivation => {
        if (cultivation.cropUsageId) {
          const cropUsage = this.cropUsages[cultivation.cropUsageId]
          const crop = this.crops[cropUsage.cropId]
          return crop.tags.includes('Applikationskarten:Maisaussaat:Modul nutzbar')
        }
      })
    }
  },
  async created () {
    if (can(this.permissions, 'Use.Frs.Cultivations')) {
      this.cultivationsByFieldId = await smarterGet('/api/v2/entities/orgunits/{orgUnitId}/cultivations/fields/{harvestYear}/crop-usage', {
        id: 'orgUnit.cultivations.MapActionControl',
        inputs: {
          orgUnitId: () => this.orgUnitId,
          harvestYear: () => this.harvestYear
        },
        onResult: (result) => {
          return result
        }
      })
      this.hasCorrectCropForCurrentHarvestYear = this.calculateCorrectCropForCurrentHarvestYear()
    } else {
      Promise.resolve()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
