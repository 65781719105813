<template lang="html">
  <div class="nutrition-status-form">
    <h3>
      <IxRes>geoMeasurements.headers.geoMeasurements</IxRes>
    </h3>

    <HelpBox>
      <IxRes>geoMeasurements.hints.fieldUniformMeasurements</IxRes>
    </HelpBox>

    <div class="scrollable-content">
      <div class="uniform-date-wrapper">
        <SimpleSwitch v-model="useUniformTimestamp">
          <IxRes>Common.SR_Common.IdenticalDate</IxRes>
        </SimpleSwitch>

        <VueDateTimePicker
          v-if="useUniformTimestamp"
          v-model="uniformTimestamp"
          format="L LT" iso
          :required="useUniformTimestamp"
          name="uniformTimestamp"
        >
          <template #label>
            <IxRes>Common.SR_Common.CommonDate</IxRes>
          </template>
        </VueDateTimePicker>
      </div>

      <MiscMeasurementsFormPart />

      <NitrogenFormPart />

      <OxideMineralFormPart />

      <HelpBox v-if="fieldStates.geometry === 'error'" type="danger">
        <span><IxRes>Common.SR_Common.SavingNotPossibleNoFieldGeometry</IxRes></span>
      </HelpBox>
    </div>

    <div class="buttons">
      <template v-if="anyNutrientHasValue">
        <IxButton
          large save
          @click="tryToSave"
        />

        <IxButton
          large cancel
          @click="cancelModalVisible = true"
        />
      </template>
      <template v-else>
        <div />

        <IxButton
          large back
          @click="stopEditing"
        />
      </template>
    </div>

    <ConfirmationModal
      v-model="cancelModalVisible"
      @confirm="discardChanges"
      @cancel="cancelModalVisible = false"
    >
      <template #title>
        <IxRes>geoMeasurements.modals.cancel.title</IxRes>
      </template>

      <IxRes>geoMeasurements.modals.cancel.text</IxRes>

      <template #confirm>
        <IxRes>geoMeasurements.modals.cancel.confirmCancellation</IxRes>
      </template>
      <template #cancel>
        <IxRes>geoMeasurements.modals.cancel.backToEditing</IxRes>
      </template>
    </ConfirmationModal>

    <FrsLoadingIndicator :loading="saving" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import ConfirmationModal from '@components/modals/ConfirmationModal'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import HelpBox from '@components/help/HelpBox'

import ReloadDataOnFieldChange from '@frs/mixins/ReloadDataOnFieldChange'
import FormPartMixin from '@components/forms/FormPartMixin'

import NitrogenFormPart from './nutrients/NitrogenFormPart'
import OxideMineralFormPart from './nutrients/OxideMineralFormPart'
import MiscMeasurementsFormPart from './nutrients/MiscMeasurementsFormPart'

export default {
  components: {
    NitrogenFormPart,
    OxideMineralFormPart,
    MiscMeasurementsFormPart,

    IxButton,
    VueDateTimePicker,
    SimpleSwitch,
    ConfirmationModal,
    FrsLoadingIndicator,
    HelpBox
  },
  mixins: [
    ReloadDataOnFieldChange,
    FormPartMixin
  ],
  props: {
    isVisible: Boolean
  },
  data () {
    return {
      cancelModalVisible: false,
      saving: false
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'fieldWkt'
    ]),
    ...mapState('fieldRecordSystem/nutritionStatus', {
      nitrogenTimestamp: state => state.measurements.nitrogen.timestamp
    }),
    ...mapGetters('fieldRecordSystem/nutritionStatus', [
      'anyNutrientHasValue'
    ]),
    ...mapFormFields('fieldRecordSystem/nutritionStatus', [
      'useUniformTimestamp'
    ]),
    ...mapFormFields('fieldRecordSystem/nutritionStatus', {
      uniformTimestamp: {
        read (value, state, getters) { return getters.uniformTimestamp }
      }
    }),
    hasGeometry () {
      return !!this.fieldWkt
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/nutritionStatus', [
      'stopEditing',
      'save'
    ]),
    validateGeometry () {
      this.$set(this.fieldStates, 'geometry', this.fieldWkt ? 'success' : 'error')
    },
    async discardChanges () {
      this.cancelModalVisible = false
      await this.$nextTick()
      this.stopEditing()
    },
    async tryToSave () {
      this.validate()
      this.validateGeometry()

      await this.$nextTick()

      if (this.state !== 'success') return

      this.saving = true
      try {
        if (this.anyNutrientHasValue) {
          await this.save()
          notifications.success(this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.CatchNutrationChangesSuccess'))
        }
        this.stopEditing()
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('Areas.FieldRecordSystem.this.SR_FieldRecordSystem.CatchNutrationChangesError'))
      }

      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrition-status-form {
  // position: relative;
  display: flex;
  flex-direction: column;

  flex: 1;
  min-height: 0;

  .scrollable-content {
    flex: 1;
    overflow-y: scroll;
    border-top: 1px solid lightgray;
    padding-top: 0.5em;

    .uniform-date-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5em;
    border-top: 1px solid lightgray;
  }
}
</style>
