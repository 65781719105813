<template lang="html">
  <div class="graph-terminator" :class="[type, direction]">
    <template v-if="direction === 'vertical' && type === 'start'">
      <div class="terminator" />
      <div class="vertical-line" />
    </template>
    <template v-if="direction === 'vertical' && type === 'end'">
      <div class="vertical-line" />
      <i class="fa fa-caret-down terminator" />
    </template>
    <template v-if="direction === 'horizontal' && type === 'start'">
      <div class="terminator" />
      <div class="horizontal-line" />
    </template>
    <template v-if="direction === 'horizontal' && type === 'end'">
      <div class="horizontal-line" />
      <i class="fa fa-caret-right terminator" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    direction: String
  }
}
</script>

<style lang="scss" scoped>
@import "./crop-rotation.scss";

.graph-terminator {
  display: flex;
  align-items: center;

  &.vertical {
    flex-direction: column;
  }
}
.start.vertical {
  height: $nodeRadius / 2;

  .terminator {
    width: 12px;
    height: $lineWidth;
    background-color: $lineColor;
  }
}
.start.horizontal {
  width: $nodeRadius / 2;

  .terminator {
    height: 12px;
    width: $lineWidth;
    background-color: $lineColor;
  }
}
.end.vertical {
  height: $nodeRadius / 2;

  .vertical-line {
    margin-bottom: -7px;
  }

  .terminator {
    font-size: 18px;
    color: $lineColor;
    margin: -5px 0;
  }
}
.end.horizontal {
  width: $nodeRadius / 2;

  // .vertical-line {
  //   margin-bottom: -7px;
  // }

  .terminator {
    font-size: 18px;
    color: $lineColor;
    // margin: -5px 0;
  }
}
</style>
