<template>
  <div>
    <div class="floating-action-button">
      <div class="fab-row">
        <div class="fab-cell">
          <div class="fab-content-left fab-outer-rows">
            <transition name="slide-top-left">
              <div v-if="showFab" class="fab-content-cell">
                <slot name="top-left" />
              </div>
            </transition>
          </div>
        </div>
        <div class="fab-cell">
          <div class="fab-content-right fab-outer-rows">
            <transition name="slide-top-right">
              <div v-if="showFab" class="fab-content-cell">
                <slot name="top-right" />
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div class="fab-row">
        <div class="fab-cell">
          <div class="fab-content-left">
            <transition name="slide-left">
              <div v-if="showFab" class="fab-content-cell">
                <slot name="left" />
              </div>
            </transition>
          </div>
        </div>

        <div key="button" class="fab-center">
          <div class="fab-circle" @click="setFAB(showFab)" @pointerdown.stop>
            <i :class="!showFab ? 'fa fa-expand' : 'fa fa-compress'" />
          </div>
        </div>

        <div key="right-cell" class="fab-cell">
          <div class="fab-content-right">
            <transition name="slide-right">
              <div v-if="showFab" class="fab-content-cell">
                <slot name="right" />
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div class="fab-row">
        <div class="fab-cell">
          <div class="fab-content-left fab-outer-rows">
            <transition name="slide-bottom-left">
              <div v-if="showFab" class="fab-content-cell">
                <slot name="bottom-left" />
              </div>
            </transition>
          </div>
        </div>

        <div class="fab-cell">
          <div class="fab-content-right fab-outer-rows">
            <transition name="slide-bottom-right">
              <div v-if="showFab" class="fab-content-cell">
                <slot name="bottom-right" />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapUiFlags} from 'src/vue/areas/field-record-system/helpers/ui'

export default {
  computed: {
    ...mapUiFlags([
      'map.showFab' // NOTE why is this called `showFab` when it actually toggles expanded state?
    ])
  },
  methods: {
    setFAB (value) {
      this.showFab = !value
    }
  }
}
</script>

<style lang="scss" scoped>

.floating-action-button {
  display: flex;
  width: 1000px;
  height: 87px;
  flex-direction: column;
}

.fab-row {
  flex: 1;
  display: flex;
  // justify-content: center;
  align-items: center;
}

.fab-cell {
  flex: 1;
  display: flex;
}

$inwardMovement: -5px;
$distance: 24px;
$calculatedPadding: $distance / 2 + $inwardMovement;

.fab-content-left {
  margin-left: auto;
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
  margin-right: -$inwardMovement;
}

.fab-content-right {
  margin-right: auto;
  min-width: 50px;
  display: flex;
  margin-left: -$inwardMovement;
}

.fab-outer-rows {
  padding: 0 $calculatedPadding 0 $calculatedPadding;
}

.fab-center {
  flex: 0;
  height: 45px;
  width: 45px;
  z-index: 0;
}

.fab-content-cell {
  position: relative;
}

$horizontalOffset: -30%;
$verticalOffset: 320%;
$horizontalFactor: 1.4;
$rotation: -80deg;

$horizontalOnlyOffset: $horizontalFactor * $horizontalOffset;

/* action boxes fade effect */
.slide-right-enter-active, .slide-right-leave-active,
.slide-left-enter-active, .slide-left-leave-active,
.slide-top-right-enter-active, .slide-top-right-leave-active,
.slide-top-left-enter-active, .slide-top-left-leave-active,
.slide-bottom-right-enter-active, .slide-bottom-right-leave-active,
.slide-bottom-left-enter-active, .slide-bottom-left-leave-active {
  transition: opacity .15s, transform .3s;
}
.slide-right-enter, .slide-right-leave-to {
  opacity: 0;
  transform: translate($horizontalOnlyOffset, 0);
}
.slide-left-enter, .slide-left-leave-to {
  opacity: 0;
  transform: translate(-$horizontalOnlyOffset, 0);
}
.slide-top-right-enter, .slide-top-right-leave-to {
  opacity: 0;
  transform: translate($horizontalOffset, -$verticalOffset) rotateZ(-$rotation);
}
.slide-top-left-enter, .slide-top-left-leave-to {
  opacity: 0;
  transform: translate(-$horizontalOffset, -$verticalOffset) rotateZ($rotation);
}
.slide-bottom-right-enter, .slide-bottom-right-leave-to {
  opacity: 0;
  transform: translate($horizontalOffset, $verticalOffset) rotateZ($rotation);
}
.slide-bottom-left-enter, .slide-bottom-left-leave-to {
  opacity: 0;
  transform: translate(-$horizontalOffset, $verticalOffset) rotateZ(-$rotation);
}

.fab-circle {
  /* circle styling */
  top: 6px;
  left: 138px;
  height: 45px;
  width: 45px;
  border-radius: 45px;
  border: 1px solid #ddd;
  background-color: white;
  z-index: 100;
  /* icon positioning */
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition for hover effect */
  transition: all .4s ease;

  cursor: pointer;
}
.fab-circle:hover {
  background-color: darken(white, 20%);
}

</style>
