<template lang="html">
  <div>
    <div class="field-header">
      <template v-if="field">
        <h1 :title="field.name">
          {{ field.name }}
        </h1>
        <!--<CultivationCalendarWrapper v-if="fieldId" :field-id="fieldId" :harvest-year="harvestYear" />-->
        <h4 v-if="showFieldCodes" class="field-code">
          <template v-if="isFieldCode">
            <IxRes>Common.SR_Common.Number</IxRes>: {{ field.code }}
          </template>
          <template v-else>
            <IxRes>Common.SR_Common.NoFieldCode</IxRes>
          </template>
        </h4>

        <br>
        <div class="button-grid">
          <IxButton edit @click="$router.push({name: 'basicFieldInfoForm'})">
            <IxRes>frs.fieldDetails.buttons.EditFieldDetails</IxRes>
          </IxButton>

          <IxButton icon="trash" @click="onDeleteField">
            <IxRes>frs.fieldDetails.buttons.deleteArea</IxRes>
          </IxButton>

          <IxButton :icon="hasGeometry ? 'edit' : 'plus'" @click="startEditingField">
            <IxRes v-if="hasGeometry">frs.fieldDetails.buttons.UpdateGeometry</IxRes>
            <IxRes v-else>frs.fieldDetails.buttons.SetGeometry</IxRes>
          </IxButton>

          <IxButton
            icon="fa fa-file-pdf-o"
            :disabled="!hasGeometry"
            :title="$i18n.translate('frs.buttons.title.missingRole')"
            @click="onExportField"
          >
            <IxRes>frs.fieldDetails.buttons.ExportGeometry</IxRes>
          </IxButton>

          <IxButton icon="map" @click="showMapManagementHandler">
            <IxRes namespace="frs.mapManagement">buttons.launchMapManagement</IxRes>
          </IxButton>
        </div>
      </template>
    </div>
    <div class="general">
      <div v-if="field.area" class="general-details">
        <div class="field-details">
          <AreaWidget
            :geometrical="field.area.geometrical || 0"
            :cultivated="field.area.cultivated || 0"
            :official="field.area.official || 0"
          />
        </div>
      </div>
      <div class="link-list" />
    </div>
    <div class="extended-details">
      <DataItem
        v-if="field.fieldUsage" label="TypeOfUse"
        localize
        namespace="Common.SR_Common"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ fieldUsage }}</IxRes>
      </DataItem>
      <DataItem label="CurrentCropRotation" localize>
        <CropRotationDisplay v-if="cropRotation" :rotation="cropRotation" :active="field.cropRotationStep" />
        <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCropRotationDeposited</IxRes>
      </DataItem>
      <DataItem label="Wasserschutzgebiet">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ waterProtectionLocalizationKeys[field.waterProtectionZone] }}</IxRes>
        <template v-if="field.natura2000">
          , <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Natura2000</IxRes>
        </template>
      </DataItem>
      <DataItem label="Erosionsgefahr">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ erosionLocalizationKeys[field.waterErosion] }}</IxRes>
        <template v-if="field.windErosion">
          , <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WindErosion</IxRes>
        </template>
      </DataItem>
    </div>
    <section class="zone-maps">
      <SectionHeader>
        <template #title>
          <IxRes>Common.SR_Common.ZoneMaps</IxRes>
        </template>
      </SectionHeader>
      <div class="extended-details">
        <!-- NOTE see FrsZoneMapSelection for reasons for :required="false" -->
        <FrsZoneMapSelection
          v-model="selectedZoneMapId" hide-label
          :field-id="fieldId"
          :required="false"
          show-on-map
        />
      </div>
    </section>
    <section v-if="this.$can('feature/DüV')" class="application-maps">
      <SectionHeader>
        <template #title>
          <IxRes>Common.SR_Common.ApplicationMaps</IxRes>
        </template>
      </SectionHeader>
      <IxButton icon="caret-right" @click="startSimonModule">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FertilizingCompensation</IxRes>
      </IxButton>
    </section>

    <br>
    <IxButton v-if="this.$can('feature/DüV')" @click="showTelemetryOverview">
      Telemetry
    </IxButton>

    <div class="weather" style="position: relative">
      <div class="weather-header">
        <SectionHeader>
          <template #title>
            <IxRes>Common.SR_Common.CurrentWeather</IxRes>
          </template>
        </SectionHeader>
        <div class="details-button">
          <IxButton icon="caret-right" :disabled="!hasGeometry" @click="setRightView('weatherDetails')">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WeatherDetailsAndForcast</IxRes>
          </IxButton>
        </div>
      </div>
      <CurrentWeather v-if="hasGeometry && climate" :climate="climate" />
      <HelpBox v-else type="warning">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoWeatherWithoutGeometry</IxRes>
      </HelpBox>
      <FrsLoadingIndicator small :loading="hasGeometry && !climate" />
    </div>
    <div class="soil-condition">
      <div class="soil-header">
        <SectionHeader>
          <template #title>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilCondition</IxRes>
          </template>
        </SectionHeader>
        <div class="details-button">
          <IxButton edit @click="$router.push({name: 'soilDetailsForm'})">
            <IxRes>Common.SR_Common.Edit</IxRes>
          </IxButton>
        </div>
      </div>
      <SoilDetails :field="field" />
    </div>

    <div class="nutrients">
      <div class="nutrient-header">
        <SectionHeader>
          <template #title>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Nutrients</IxRes>
          </template>
        </SectionHeader>
        <div class="details-button">
          <IxButton edit @click="startEditingNutritionStatus">
            <IxRes>Common.SR_Common.Edit</IxRes>
          </IxButton>
        </div>
      </div>
      <NutritionDetails :field="field" />
    </div>

    <div class="default-yields">
      <div class="default-yields-header">
        <SectionHeader>
          <template #title>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DY_DefaultYields</IxRes>
          </template>
        </SectionHeader>
        <div class="details-button">
          <IxButton edit @click="startEditingDefaultYields">
            <IxRes>Common.SR_Common.Edit</IxRes>
          </IxButton>
        </div>
      </div>
    </div>

    <ConfirmationModal
      v-model="isFieldConfirmationModalOpen"
      @confirm="confirmDeleteFieldConfirmed" @cancel="confirmDeleteFieldCancelled"
    >
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteField</IxRes>
      </template>
      <h3>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteFieldQuestion</IxRes>
      </h3>
      <strong> {{ field.name }} </strong>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.IrretrievableAction</IxRes>
    </ConfirmationModal>

    <IxVectorLayer
      :features="greenspinFeatures"
      :vector-style="style"
      :z-index="1"
      layer-id="greenspin"
      auto-focus
    />

    <FrsLoadingIndicator :loading="loading" />
    <DarkenPanel :display="darkenPanel" />

    <FrsFab v-if="!editing.active" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {mapUiFlags} from '@frs/helpers/ui'
import {notifications} from 'src/js/infrastructure'
import {zonemap} from '@frs/map-styles'

import ConfirmationModal from '@components/modals/ConfirmationModal'
import CurrentWeather from '@frs/components/field-details/weather/CurrentWeather'
import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import HelpBox from '@components/help/HelpBox'
import IxVectorLayer from '@components/map/IxVectorLayer'
import CropRotationDisplay from '@frs/components/crop-rotation/CropRotationDisplay'
import * as FieldDetails from '@frs/components/field-details'
import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'

import SectionHeader from './details/SectionHeader'
import DataItem from './details/DataItem'
// import CultivationCalendarWrapper from './details/CultivationCalendarWrapper'

import makeReloadDataMixin from '@frs/mixins/make-reload-data-mixin'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import PermissionMixin from '@mixins/PermissionMixin'

import DarkenPanel from '@frs/components/geometry-editing/DarkenPanel'
import FrsFab from '@frs/components/map/FrsFab'

export default {
  components: {
    // CultivationCalendarWrapper,
    SectionHeader,
    DataItem,
    CropRotationDisplay,
    ...FieldDetails,
    HelpBox,
    IxVectorLayer,
    CurrentWeather,
    IxButton,
    ConfirmationModal,
    FrsLoadingIndicator,
    FrsZoneMapSelection,
    FrsFab,
    DarkenPanel
  },
  mixins: [
    makeReloadDataMixin([
      state => state.fieldRecordSystem.userSettings.harvestYear,
      state => state.fieldRecordSystem.navigation.location.fieldId
    ]),
    MirrorRouteMixin,
    PermissionMixin
  ],
  data: function () {
    return {
      isCollapsed: false,
      showForm: false,
      editData: false,
      editNutritionStatus: false,
      isFieldConfirmationModalOpen: false,
      cancelModalVisible: false,
      loading: false,
      greenspinFeatures: [],
      selectedZoneMapId: null
    }
  },
  computed: {
    ...mapResources([
      'Messages.State.SR_StateMessages',
      'frs.zoneMaps.creation.messages'
    ]),
    ...mapState('fieldRecordSystem', {
      fieldDataLookup: state => state.data.field,
      cropRotation: state => state.cropRotations.field,
      cultivations: state => state.cultivation.data.selection.orgUnit,
      editing: state => state.map.editing
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    ...mapGetters('fieldRecordSystem/navigation', [
      'parentLookup'
    ]),
    zoneMapIds () {
      return [this.selectedZoneMapId]
    },
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'harvestYear'
    ]),
    ...mapUiFlags([
      'dashboard.showFieldCodes'
    ]),
    fieldUsage () {
      return `FieldUsage_${this.field.fieldUsage}`
    },
    hasGeometry () {
      return !!this.field.wkt
    },
    field () {
      const field = this.entityLookup[this.fieldId]
      const fieldData = this.fieldDataLookup[this.fieldId]
      return Object.assign({}, field, fieldData)
    },
    climate () {
      return this.field.climate
    },
    isFieldCode () {
      return this.field.code !== ''
    },
    waterProtectionLocalizationKeys () {
      return {
        0: 'NoZonesDeposited',
        1: 'ProtectionZoneOne',
        2: 'ProtectionZoneTwo',
        3: 'ProtectionZoneThree'
      }
    },
    erosionLocalizationKeys () {
      return {
        0: 'NoHazardsDeposited',
        1: 'WaterErosionCCWaterOne',
        2: 'WaterErosionCCWaterTwo'
      }
    },
    darkenPanel () {
      return this.editing.active && this.editing.flavor === 'quick'
    },
    style () {
      return zonemap
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    ...mapActions('fieldRecordSystem/download', [
      'startDownload'
    ]),
    ...mapActions('fieldRecordSystem', [
      'deleteField',
      'startEditingField'
    ]),
    ...mapActions('fieldRecordSystem/nutritionStatus', {
      'startEditingNutritionStatus': 'startEditing'
    }),
    ...mapActions('fieldRecordSystem/action', [
      'showDefaultYield'
    ]),
    ...mapActions('fieldRecordSystem/defaultYields', [
      'startEditingDefaultYields'
    ]),
    ...mapActions('fieldRecordSystem/telemetry', [
      'showTelemetryOverview'
    ]),
    ...mapActions('fieldRecordSystem/simonModule', [
      'startSimonModule'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadGeometryForField'
    ]),
    toggle () {
      this.isCollapsed = !this.isCollapsed
      this.$nextTick(() => this.$emit('changed'))
    },
    showEditing (modalName) {
      this.showForm = false
      this.editData = true
      this[modalName] = true
    },
    showMapManagementHandler () {
      this.$router.push({name: this.createParallelRoute('mapManagement')})
    },
    onExportField () {
      this.loadGeometryForField()
      this.startDownload({type: 'fieldBorder', ids: [this.field.id]})
    },
    onDeleteField () {
      this.isFieldConfirmationModalOpen = true
    },
    confirmDeleteFieldConfirmed () {
      this.isFieldConfirmationModalOpen = false
      this.loading = true
      const parentId = this.parentLookup[this.fieldId]
      this.deleteField()
        .then(() => {
          this.$router.push({name: 'orgDashboard', params: {orgUnitId: parentId}})
          notifications.success(this.SR_StateMessages.DeleteSuccess)
        })
        .catch(error => {
          notifications.error(this.SR_StateMessages.DeleteError)
          console.error(error)
        })
        .then(() => {
          this.loading = false
        })
    },
    confirmDeleteFieldCancelled () {
      this.isFieldConfirmationModalOpen = false
    },
    nutrientModalCancelClicked () {
      this.editNutritionStatus = false
      this.cancelModalVisible = true
    },
    refuseCancel () {
      this.cancelModalVisible = false
      this.editNutritionStatus = true
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
  .general-details {
    display: flex;
    flex-direction: column-reverse;

    .link-list {
      flex-direction: row;
      margin-bottom: 10px;

      a {
        flex-grow: 1;
        margin-right: 5px;
      }
    }
  }
}

</style>

<style lang="scss" scoped>
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.25em;

  button {
    margin-top: 4px;
    margin-right: 4px;
    text-align: left;
  }
}

.field-code {
  color: black;
  margin-top: -8px;
  margin-bottom: -14px;
}
.field-header{
  color: #b9b9b9;

  h1 {
    margin-top: 0px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.general, .general-details, .link-list, .nutrients, .field-details,
.usage-type, .areas-container, .areas, .additional-area-info, .geometrical, .geometrical-details, .weather, .weather-header, .zone-maps-header,
.soil-condition, .soil-header, .nutrients, .nutrient-header, .default-yields, .default-yields-header, .zone-maps {
  display: flex;
}
.general, .link-list, .areas-container, .additional-area-info, .usage-type, .geometrical-details {
  flex-direction: column;
}
.field-details {
  padding: 10px;
  // flex: 2;
}
.link-list, .areas, .geometrical-details, .additional-area-info, .usage-type, .areas-container {
  flex: 1;
}
.geometrical-details {
  align-items: center;
  p {font-size: 20px;}
}
.link-list {
  align-items: flex-end;
  margin-top: 10px;
}
.extended-details {
  padding: 10px;
}
.weather, .soil-condition, .nutrients, .zone-maps {
  flex-direction: column;
}
.details-button {
  flex: none;
  padding-left: 10px;
  padding-top: 7px;
}
small {
  color: #c2c2c2;
}
p {
  margin: 0;
}
label {
  margin-bottom: 0px;
}
</style>
