import Overlay from 'ol/Overlay'
import {transform} from 'ol/proj'

export default {
  inject: [
    'getMap'
  ],
  props: {
    position: {},
    centered: Boolean
  },
  computed: {
    olPosition () {
      return this.position
        ? transform(this.position, 'EPSG:4326', 'EPSG:3857')
        : null
    }
  },
  created () {
    this.getMap().then(map => {
      this.map = map

      this.overlay = new Overlay({
        position: this.olPosition,
        positioning: this.centered ? 'center-center' : 'bottom-left',
        stopEvent: this.stopEvent === undefined ? true : this.stopEvent
      })

      if (this.$el) {
        this.$el.remove()
        this.overlay.setElement(this.$el)
      }

      this.map.addOverlay(this.overlay)
    })
  },
  mounted () {
    this.$el.style.whiteSpace = 'nowrap'

    if (!this.overlay) {
      this.$el.remove()
    } else if (!this.overlay.getElement()) {
      this.$el.remove()
      this.overlay.setElement(this.$el)
    }
  },
  updated () {

  },
  watch: {
    olPosition (position) {
      if (this.overlay) {
        this.overlay.setPosition(position)
      }
    }
  },
  beforeDestroy () {
    if (this.map) {
      this.map.removeOverlay(this.overlay)
    }
  }
}
