<template lang="html">
  <div v-if="storeData">
    <p class="lead">
      <IxRes>Common.SR_Common.General</IxRes>
    </p>
    <dl class="clearfix">
      <dt class="col-sm-5">
        <IxRes>Common.SR_Common.Name</IxRes>
      </dt>
      <dd class="col-sm-7">
        {{ storeData.name }}
      </dd>
    </dl>
    <dl class="clearfix">
      <dt class="col-sm-5">
        <IxRes>Common.SR_Common.TypeOfUse</IxRes>
      </dt>
      <dd class="col-sm-7">
        {{ storeData.usage.value }}
      </dd>
    </dl>
    <p class="lead">
      <IxRes>Common.SR_Common.Areas</IxRes>
    </p>
    <dl class="clearfix">
      <dt class="col-sm-5">
        <IxRes>Common.SR_Common.Official</IxRes>
      </dt>
      <dd class="col-sm-7">
        {{ storeData.area.official / 10000 }} ha
      </dd>

      <dt class="col-sm-5">
        <IxRes>Common.SR_Common.Cultivated</IxRes>
      </dt>
      <dd class="col-sm-7">
        {{ storeData.area.cultivated / 10000 }} ha
      </dd>

      <dt class="col-sm-5">
        <IxRes>Common.SR_Common.Geometrical</IxRes>
      </dt>
      <dd class="col-sm-7">
        {{ $i18n.format(storeData.area.geometrical / 10000) }} ha
      </dd>
    </dl>
    <p class="lead">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CurrentCropRotation</IxRes>
    </p>
    <CropRotationDisplay v-if="cropRotation" :rotation="cropRotation" :active="cropRotationStep" />
    <div v-else class="alert alert-info">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCropRotationDeposited</IxRes>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import CropRotationDisplay from '@frs/components/crop-rotation/CropRotationDisplay'

export default {
  components: {
    CropRotationDisplay
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldDataLookup: state => state.data.field,
      cropRotation: state => state.cropRotations.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    ...mapGetters('fieldRecordSystem', [
      'cropRotationStep'
    ]),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    field () {
      return this.entityLookup[this.fieldId]
    },
    fieldData () {
      return this.fieldDataLookup[this.fieldId]
    },
    storeData () {
      if (!this.fieldData ||
        !this.fieldData.area ||
        !this.fieldData.usage) {
        return
      }
      const {name, id} = this.field
      const {area: {official, cultivated, geometrical}, usage: {value, short}} = this.fieldData
      return {id, name, area: {official, cultivated, geometrical}, usage: {value, short}}
    }
  }
}
</script>

<style lang="scss" scoped>
.lead {
  margin-top: 0;
  margin-bottom: 0;
}
div.form-group {
  padding: 0 0px;
}
div.alert {
  padding: 5px;
  height: 34px;
}
</style>
