<template lang="html">
  <div class="section-header">
    <div class="section-title">
      <slot name="title">
        <span v-if="!$slots.title && title">{{ title }}</span>
      </slot>
    </div>
    <div class="section-content">
      <a
        v-if="$listeners && $listeners.edit" class="btn-mobile"
        href="#"
        @click.prevent="$emit('edit')"
      ><IxRes>Common.SR_Common.Edit</IxRes></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {}
  }
}
</script>

<style lang="scss" scoped>
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
  .section-content {
    margin-top: 15px;
  }
}
</style>

<style lang="scss" scoped>
.section-header {
  margin-bottom: 5px;
}
.section-title {
  font-size: 2em;
  font-weight: 300;
  color: darkgray;
  /* color: gray; */
  margin-bottom: -10px;
}
</style>
