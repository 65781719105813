<template lang="html">
  <div class="weather-icon">
    <img
      :title="SR_Common[image.title]" :width="size"
      :height="size"
      :src="image.src" alt=""
    >
    <p class="weather-details">
      <IxRes>Common.SR_Common.{{ image.title }}</IxRes>
    </p>
    <p class="weather-details">
      <i class="fa fa-thermometer-empty" aria-hidden="true" /> {{ formattedTemperature }} {{ $i18n.translateUnit('°C') }}
    </p>
    <p class="weather-details">
      <i class="fa fa-tint" aria-hidden="true" /> {{ formattedPrecipitation }} {{ $i18n.translateUnit('%') }}
    </p>
  </div>
</template>

<script>
import {maybeAddBackend} from 'src/js/infrastructure'
import {mapResources} from '@helpers/vuex'

export default {
  props: {
    totalCloudCover: Number,
    size: Number,
    precipitation: Number,
    temperature: Number
  },
  computed: {
    ...mapResources([
      'Common.SR_Common'
    ]),
    formattedPrecipitation () {
      return this.$i18n.format(this.precipitation, 'f1')
    },
    formattedTemperature () {
      return this.$i18n.format(this.temperature, 'f1')
    },
    image () {
      const rootPath = maybeAddBackend('/images/Weather')
      let title = ''
      let path = ''

      // sonnig
      path = `${rootPath}/sun.svg`
      title = 'Sunny'

      // wolkig
      if (this.totalCloudCover > 0 && this.totalCloudCover < 25) {
        path = `${rootPath}/clouds1.svg`
        title = 'LightCloudy'
      }
      if (this.totalCloudCover >= 25 && this.totalCloudCover < 50) {
        path = `${rootPath}/clouds2.svg`
        title = 'PartlyCloudy'
      }
      if (this.totalCloudCover >= 50 && this.totalCloudCover < 75) {
        path = `${rootPath}/clouds3.svg`
        title = 'MostlyCloudy'
      }
      if (this.totalCloudCover >= 75) {
        path = `${rootPath}/clouds4.svg`
        title = 'Cloudy'
      }

      // regen oder schnee
      if (this.precipitation > 0 && this.precipitation < 1.5) {
        path = this.temperature > 0 ? `${rootPath}/rain1.svg` : `${rootPath}/sleet.svg`
        title = this.temperature > 0 ? 'Drizzle' : 'Sleet'
      }
      if (this.precipitation >= 1.5 && this.precipitation < 14) {
        path = this.temperature > 0 ? `${rootPath}/rain2.svg` : `${rootPath}/snow.svg`
        title = this.temperature > 0 ? 'Rain' : 'Snow'
      }
      if (this.precipitation >= 14) {
        path = this.temperature > 0 ? `${rootPath}/rain3.svg` : `${rootPath}/snow.svg`
        title = this.temperature > 0 ? 'HeavyRain' : 'Snow'
      }

      return {src: path, title}
    }
  }
}
</script>

<style lang="scss">
  .weather-icon {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .weather-details {
      display: flex;
      align-items: center;

      i {
        padding-right: 4px;
      }
    }
  }
</style>
