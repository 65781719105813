<template lang="html">
  <section class="nutrition-details">
    <template v-if="field.nutrients && Object.keys(field.nutrients).length">
      <div class="details-row">
        <DataItem v-if="field.nutrients.humusContent" label="HumusContent" localize>
          {{ $i18n.format(field.nutrients.humusContent.value) }} {{ $i18n.translateUnit('m') }}
        </DataItem>
        <DataItem
          v-if="field.nutrients.waterLevel"
          label="SoilMoisture" description="SoilProbe"
          localize
        >
          {{ $i18n.format(field.nutrients.waterLevel.value) }} {{ $i18n.translateUnit('mm') }}
        </DataItem>
        <DataItem v-if="field.nutrients.ph" label="pH">
          {{ $i18n.format(field.nutrients.ph.value) }}
        </DataItem>
        <DataItem v-if="field.nutrients.nitrogen" label="Nmin">
          {{ $i18n.format(field.nutrients.nitrogen.value) }} {{ $i18n.translateUnit('mg/ha') }}
        </DataItem>
      </div>
      <div class="details-row">
        <DataItem v-if="field.nutrients.phosphorus">
          <template #label>
            P
            <template v-if="field.nutrients.phosphorus.probingMethod">
              ({{ field.nutrients.phosphorus.probingMethod }})
            </template>
          </template>
          {{ $i18n.format(field.nutrients.phosphorus.value) }} {{ $i18n.translateUnit('mg/100g') }}
        </DataItem>
        <DataItem v-if="field.nutrients.potassium" label="K">
          {{ $i18n.format(field.nutrients.potassium.value) }} {{ $i18n.translateUnit('mg/100g') }}
        </DataItem>
        <DataItem v-if="field.nutrients.magnesium" label="Mg">
          {{ $i18n.format(field.nutrients.magnesium.value) }} {{ $i18n.translateUnit('mg/100g') }}
        </DataItem>
      </div>
    </template>

    <HelpBox v-else>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoNutritionData</IxRes>
    </HelpBox>
  </section>
</template>

<script>
import HelpBox from '@components/help/HelpBox'

import DataItem from './DataItem'

export default {
  components: {
    DataItem,
    HelpBox
  },
  props: {
    field: Object
  }
}
</script>

<style lang="scss" scoped>
.nutrition-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;

  .alert {
    width: 100%;
  }
}
.details-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
