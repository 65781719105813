<template lang="html">
  <div class="data-item">
    <label :title="title">
      <IxRes v-if="localize">{{ namespace }}.{{ label }}</IxRes>
      <slot v-else name="label">{{ label }}</slot>
    </label>
    <p class="data-value">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    description: String,
    localize: Boolean, // treat `label` and `description` as a translation key inside the `namespace`
    namespace: {
      type: String,
      default: 'Areas.FieldRecordSystem.SR_FieldRecordSystem'
    }
  },
  computed: {
    title () {
      return this.description && this.localize
        ? this.$i18n.translate(`${this.namespace}.${this.description}`)
        : this.description
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-size: 1.1em;
  /* font-weight: normal; */
  color: gray;
  /* margin-bottom: 17px; */
}
.data-value {
  font-size: 1.4em;
  font-weight: 300;
  /* line-height: 0.8em; */
  margin-bottom: 0.5em;
}
</style>
