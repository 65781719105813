<template lang="html">
  <div>
    <h4>
      <IxRes>Common.SR_Common.Nitrogen</IxRes>
    </h4>
    <!-- <template #description>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NitrogenTitle</IxRes>
    </template> -->

    <NutrientMeasurementInput
      v-model="nitrogen" :original-data="originalMeasurements.nitrogen"
      name="nitrogen" :readonly-timestamp="useUniformTimestamp"
    >
      <template #input>
        <NumericInput
          v-model="layer30" name="nitrogen_layer30"
          label="0 - 30 cm"
          :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.NitrogenTitle')"
          :placeholder="originalMeasurements.nitrogen && originalMeasurements.nitrogen.layer30 !== null ? $i18n.format(originalMeasurements.nitrogen.layer30): ''"
          unit="kg/ha"
        />
        <NumericInput
          v-model="layer60" name="nitrogen_layer60"
          label="30 - 60 cm"
          :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.NitrogenTitle')"
          :placeholder="originalMeasurements.nitrogen && originalMeasurements.nitrogen.layer60 !== null ? $i18n.format(originalMeasurements.nitrogen.layer60): ''"
          unit="kg/ha"
        />
        <NumericInput
          v-model="layer90" name="nitrogen_layer90"
          label="60 - 90 cm"
          :description="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.NitrogenTitle')"
          :placeholder="originalMeasurements.nitrogen && originalMeasurements.nitrogen.layer90 !== null ? $i18n.format(originalMeasurements.nitrogen.layer90): ''"
          unit="kg/ha"
        />
      </template>
    </NutrientMeasurementInput>

    <TextInput
      v-model="note" name="note"
      multiline :rows="2"
    >
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NitrogenNote</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'

import NutrientMeasurementInput from './NutrientMeasurementInput'

import OriginalMeasurementsMixin from './OriginalMeasurementsMixin'

export default {
  components: {
    NumericInput,
    TextInput,
    NutrientMeasurementInput
  },
  mixins: [
    OriginalMeasurementsMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/nutritionStatus', [
      'measurements.nitrogen',
      'measurements.nitrogen.layer30',
      'measurements.nitrogen.layer60',
      'measurements.nitrogen.layer90',
      'measurements.nitrogen.note'
    ]),
    ...mapState('fieldRecordSystem/nutritionStatus', [
      'useUniformTimestamp'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
