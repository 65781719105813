import { render, staticRenderFns } from "./OxideMineralFormPart.vue?vue&type=template&id=5072ed37&scoped=true&lang=html&"
import script from "./OxideMineralFormPart.vue?vue&type=script&lang=js&"
export * from "./OxideMineralFormPart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5072ed37",
  null
  
)

export default component.exports