<template lang="html">
  <div class="crop-rotation-display">
    <small v-if="rotation.name">{{ rotation.name }}</small>
    <div class="crop-display">
      <GraphTerminator type="start" direction="horizontal" />
      <div v-for="(step, index) in rotation.steps" :key="index" class="crop-entry">
        <div class="crop-body">
          <div class="crop-rotation-graph node-container">
            <div class="horizontal-line" />
            <CropUsageNode :crop-usage-id="step.cropUsageId" :active="active === index" />
            <div class="horizontal-line" />
          </div>
        </div>
      </div>
      <div class="crop-rotation-graph">
        <!-- <midpoint-node></midpoint-node> -->
        <GraphTerminator type="end" direction="horizontal" />
      </div>
    </div>
  </div>
</template>

<script>
import CropUsageNode from './CropUsageNode'
import GraphTerminator from './GraphTerminator'

export default {
  components: {
    CropUsageNode,
    GraphTerminator
  },
  props: {
    rotation: Object,
    active: Number
  }
}
</script>

<style lang="scss" scoped>
@import "./crop-rotation.scss";

.crop-rotation-display, .crop-display, .crop-entry, .crop-rotation-graph {
  display: flex;
}
.crop-display {
  font-size: 1.4rem;
  margin-top: 5px;
}
.crop-rotation-display {
  flex-direction: column;
}

small {
  color: $lineColor;
}
.crop-rotation-graph {
  align-items: center;
  height: $nodeRadius * 2;

  &.node-container {
    min-width: $nodeRadius * 2.5;
  }
}
</style>
