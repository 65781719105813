<template lang="html">
  <div class="crop-node" :class="{editable, active}" :title="cropUsageTranslations.name">
    <span class="abbreviation" :class="{leguminous: crop.isLeguminous}">
      <IxRes v-if="cropUsageTranslations.abbreviation">masterData.crops.usage.{{ cropUsageId }}_abbreviation</IxRes>
      <IxRes v-else :process="x => x.substring(0, 3)">masterData.crops.usage.{{ cropUsageId }}_name</IxRes>
    </span>
    <div v-if="editable" class="close-overlay" @click="$emit('click')">
      <i class="fa fa-close" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {getCropUsageTranslations} from '@frs/helpers/crops'

export default {
  props: {
    editable: Boolean,
    active: Boolean,
    cropUsageId: String
  },
  computed: {
    ...mapState('masterData', [
      'crops',
      'cropUsages'
    ]),
    cropUsageTranslations () {
      return getCropUsageTranslations(this.cropUsageId)
    },
    crop () {
      const usage = this.cropUsages[this.cropUsageId]

      return this.crops[usage.cropId]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./crop-rotation.scss";

.crop-node, .close-overlay {
  display: flex;
}

.crop-node:hover .close-overlay {
  opacity: 1;
}

.crop-node, .abbreviation, .close-overlay {
  transition: all 0.35s;
}

.crop-node {
  @include circle($nodeRadius);
  border: $lineWidth solid;

  font-weight: bold;
  cursor: default;

  position: relative;
  align-items: center;
  justify-content: center;

  .close-overlay {
    @extend %overlay;

    align-items: center;
    justify-content: center;

    font-size: 1.5em;
    opacity: 0;
    color: red;
  }

  .abbreviation.leguminous {
    color: rgb(0, 176, 23);
  }
}

.crop-node.editable {
  cursor: pointer;

  &:hover .abbreviation {
    opacity: 0;
  }
}

.crop-node.active {
  background-color: rgb(132, 186, 224);
}
.crop-node:not(.active) {
  border-color: $lineColor;
}

.crop-node:not(.editable):not(.active) .abbreviation {
  color: $lineColor;

  &.leguminous {
    color: rgb(91, 172, 101);
  }
}
</style>
