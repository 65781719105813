<template lang="html">
  <section class="soil-details">
    <template v-if="field.soil">
      <div class="details-row">
        <DataItem class="item" label="SoilType" localize>
          {{ field.soil.soilType ? field.soil.soilType : SR_FieldRecordSystem.NoType }}
        </DataItem>
        <DataItem class="item" label="SoilSubType" localize>
          {{ soilSubType }}
        </DataItem>
      </div>
      <div class="details-row">
        <DataItem
          class="item" label="SoilQuality"
          description="SoilQualityDescription"
          localize
        >
          {{ field.soil.soilQuality | nullToDash }}
        </DataItem>
        <DataItem
          class="item" label="UsableFieldCapacity"
          description="UsableFieldCapacityDescription"
          localize
        >
          {{ field.soil.usableFieldCapacity | nullToDash }} {{ $i18n.translateUnit('mm') }}
        </DataItem>
      </div>
    </template>
    <div v-else class="alert alert-info">
      No soil data
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {mapResources} from '@helpers/vuex'

import DataItem from './DataItem'

export default {
  components: {
    DataItem
  },
  filters: {
    nullToDash (value) {
      return value === null ? '-' : value
    }
  },
  props: [
    'field'
  ],
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      soilSubTypes: state => state.data.soilSubTypes
    }),
    soilSubType () {
      return this.field.soil.soilSubType
        ? this.soilSubTypes.find(type => type.id === this.field.soil.soilSubType).name
        : this.SR_FieldRecordSystem.NoSubType
    }
  }
}
</script>

<style lang="scss" scoped>
.soil-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
}
.details-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item {
  width: 45%;
}
</style>
