<template lang="html">
  <div class="widget">
    <svg class="svg" :style="dimensionStyle" shape-rendering="geometricPrecision">
      <path
        v-for="(arc, index) in arcs" :key="index"
        fill="none"
        :stroke="arc.color"
        :stroke-width="arc.stroke"
        :d="arc.path"
      />
      <text
        :x="textPosition.x"
        :y="textPosition.y"
        font-size="11"
        stroke="none"
        :fill="textColor"
      >
        100%
      </text>
    </svg>
    <div class="areas">
      <div class="area">
        <div class="legend" style="background-color: rgba(100,190,20,1.0);" />
        <div class="areaType">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivatedAbbreviation</IxRes> {{ $i18n.format(cultivated, 'hectare') }}
        </div>
      </div>
      <div class="area">
        <div class="legend" style="background-color: rgba(65,100,190,1.0);" />
        <div class="areaType">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.GeometricalAbbreviation</IxRes> {{ $i18n.format(geometrical, 'hectare') }}
        </div>
      </div>
      <div class="area">
        <div class="legend" style="background-color: rgba(65,100,190,0.4);" />
        <div class="areaType">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.OfficialAbbreviation</IxRes> {{ $i18n.format(official, 'hectare') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'official',
    'geometrical',
    'cultivated'
  ],
  data () {
    return {
      cultivationArc: {
        radius: 42,
        color: 'rgba(100,190,20,1.0)',
        stroke: '8px'
      },
      cultivationOverrideColor: 'rgba(100,190,20,0.4)',
      geometricalArc: {
        radius: 34,
        color: 'rgba(65,100,190,1.0)',
        stroke: '8px'
      },
      officialArc: {
        radius: 28,
        color: 'rgba(65,100,190,0.4)',
        stroke: '4px'
      },
      dimension: {
        height: '100',
        width: '200'
      },
      limits: {
        start: 210,
        end: 150
      },
      defaultColor: 'rgb(200,200,200)',
      arcs: []
    }
  },
  computed: {
    dimensionStyle () {
      return {
        height: this.dimension.height + 'px',
        width: this.dimension.width + 'px'
      }
    },
    maxArea () {
      return Math.max(this.official, this.cultivated, this.geometrical)
    },
    range () {
      return 360 - this.limits.start + this.limits.end
    },
    textColor () {
      const cultivated = Object.assign({}, this.cultivationArc, {area: this.roundArea(this.cultivated)})
      const official = Object.assign({}, this.officialArc, {area: this.roundArea(this.official)})
      const geometrical = Object.assign({}, this.geometricalArc, {area: this.roundArea(this.geometrical)})

      // note: by adding the geometrical area first this will be the default color in case several areas are the same
      const area = [geometrical, cultivated, official].reduce((acc, curr) => {
        if (curr.area > acc.area) {
          return curr
        }
        return acc
      }, {area: 0, color: this.defaultColor})
      return area.color
    },
    textPosition () {
      const parameter = this.generatePathParameter(this.geometricalArc.radius, this.limits.start, 359)
      return {x: parameter.end.x + 6, y: parameter.end.y + 6}
    }
  },
  methods: {
    polarToCartesian (radius, degrees) {
      const radians = (degrees - 90) * Math.PI / 180.0
      return {
        x: (this.dimension.height / 2) + (radius * Math.cos(radians)),
        y: (this.dimension.height / 2) + (radius * Math.sin(radians))
      }
    },
    generatePathParameter (radius, startAngle, endAngle) {
      const start = this.polarToCartesian(radius, endAngle)
      const end = this.polarToCartesian(radius, startAngle)
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
      return {
        start, end, radius, largeArcFlag
      }
    },
    generatePath (parameter) {
      const d = [
        'M', parameter.start.x, parameter.start.y,
        'A', parameter.radius, parameter.radius, 0, parameter.largeArcFlag, 0, parameter.end.x, parameter.end.y
      ].join(' ')
      return d
    },
    generateArcs (areaSize, color, stroke, radius) {
      if (this.maxArea === 0) {
        // in case we have a new field
        const parameterFirstSector = this.generatePathParameter(this.geometricalArc.radius, this.limits.start, 359.99)
        this.arcs.push({path: this.generatePath(parameterFirstSector), color: this.defaultColor, stroke: this.geometricalArc.stroke})
        // second sector
        const parameterSecondSector = this.generatePathParameter(this.geometricalArc.radius, 0, 150)
        this.arcs.push({path: this.generatePath(parameterSecondSector), color: this.defaultColor, stroke: this.geometricalArc.stroke})
        return
      }

      let percentage = areaSize / this.maxArea

      // first sector reaches from this.limits.start to 360
      // second sector reaches from 0 tp this.limits.end
      if (percentage < 0.5) {
        // for the first sector we need one arc starting from this.limits.start
        const parameter = this.generatePathParameter(radius, this.limits.start, this.computeFirstSectorAngle(percentage))
        const path = this.generatePath(parameter)
        this.arcs.push({path, color, stroke})
      } else {
        // for the second sector we need two arcs

        // first sector
        const parameterFirstSector = this.generatePathParameter(radius, this.limits.start, 359.99)
        this.arcs.push({path: this.generatePath(parameterFirstSector), color, stroke})
        // second sector
        const parameterSecondSector = this.generatePathParameter(radius, 0, this.computeSecondSectorAngle(percentage))
        this.arcs.push({path: this.generatePath(parameterSecondSector), color, stroke})
      }
    },
    computeFirstSectorAngle (percentage) {
      // compute sector angle with in the first sector
      const sectorAngle = this.range * percentage + this.limits.start

      // some adjustments to the angle in case the sector angle is very small
      return sectorAngle < this.limits.start + 5 ? this.limits.start + 5 : sectorAngle
    },
    computeSecondSectorAngle (percentage) {
      // compute sector angle with in the second sector
      // basically we compute the sector angle for the whole range and subtract the first sector since
      // that one is covered by the first arc already
      return (this.range * percentage) - (360 - this.limits.start)
    },
    computeArcs () {
      this.arcs = []

      // geometrical area
      this.generateArcs(this.geometrical, this.geometricalArc.color, this.geometricalArc.stroke, this.geometricalArc.radius)

      // cultivation area
      this.generateArcs(this.cultivated, this.cultivationArc.color, this.cultivationArc.stroke, this.cultivationArc.radius)

      // official area
      this.generateArcs(this.official, this.officialArc.color, this.officialArc.stroke, this.officialArc.radius)
    },
    roundArea (area) {
      return Math.round(100 * area) / 100
    }
  },
  watch: {
    geometrical () {
      this.computeArcs()
    },
    official () {
      this.computeArcs()
    },
    cultivated () {
      this.computeArcs()
    }
  },
  mounted () {
    this.computeArcs()
  }
}
</script>

<style lang="scss" scoped>
.widget, .svg, .areas, .area, {display: flex;}
.svg {
  flex: 1;
}
.areas {
  flex: 1.8;
  flex-direction: column;
  justify-content: space-evenly;
}
.legend {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  border: 1px solid rgba(0, 0, 0, .2);
}
</style>
