<template lang="html">
  <div>
    <h4>
      <IxRes v-if="oxide">Common.SR_Common.{{ legacyNutrientName }}Oxide</IxRes>
      <IxRes v-else>Common.SR_Common.{{ legacyNutrientName }}</IxRes>
    </h4>

    <slot />

    <SimpleSwitch v-model="oxideProxy">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Oxideform</IxRes>
      <template #offLabel>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.PureNutrientForm</IxRes>
      </template>
    </SimpleSwitch>

    <NutrientMeasurementInput
      v-model="valueProxy"
      :name="nutrient" :original-data="correctedOriginalData"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import {calculateToOxidform, calculateFromOxidform} from '@frs/components/basic-fertilization/oxidform-calculation'

import SimpleSwitch from '@components/forms/SimpleSwitch'

import NutrientMeasurementInput from './NutrientMeasurementInput'

export default {
  components: {
    SimpleSwitch,
    NutrientMeasurementInput
  },
  props: {
    value: Object, // {value, timestamp}
    nutrient: String,
    oxide: Boolean,
    originalData: Object
  },
  computed: {
    legacyNutrientName () {
      return this.nutrient[0].toUpperCase() + this.nutrient.substring(1)
    },
    correctedOriginalData () {
      return this.originalData && this.originalData.value !== null && this.oxide
        ? {
          ...this.originalData,
          value: calculateToOxidform(this.nutrient, this.originalData.value)
        }
        : this.originalData
    },
    valueProxy: {
      get () {
        const data = this.value
        return this.oxide && data.value !== null
          ? {...data, value: calculateToOxidform(this.nutrient, data.value)}
          : data
      },
      set (data) {
        if (this.oxide && data.value !== null) {
          data = {...data, value: calculateFromOxidform(this.nutrient, data.value)}
        }
        this.$emit('input', data)
      }
    },
    oxideProxy: {
      get () {
        return this.oxide
      },
      set (oxide) {
        this.$emit('update:oxide', oxide)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
