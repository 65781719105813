<template lang="html">
  <div class="frs-loading-overlay" :class="{show: show}" />
</template>

<script>

export default {
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show () {
      return this.display
    }
  }
}
</script>

<style lang="scss" scoped>
.frs-loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  opacity: 0;
  pointer-events: none;
}

.show {
  background-color: #dedede;
  opacity: .65;
  pointer-events: all;
}
</style>
