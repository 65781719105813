<template>
  <div
    class="fab-button" :class="{expanded, locked, placeholder}"
    :title="title"
    @click="onClick"
    @pointerdown.stop @pointermove.stop
  >
    <i v-if="icon || locked" :class="'fa fa-' + (locked ? 'lock' : icon)" aria-hidden="true" />
    <slot v-if="!placeholder && text">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ text }}</IxRes>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    expanded: Boolean,
    locked: Boolean,
    icon: String,
    placeholder: Boolean,
    hoverText: String
  },
  computed: {
    title () {
      if (!this.locked) return

      return this.hoverText
        ? this.hoverText
        : this.$i18n.translate('frs.fabButtons.hoverText.notAuthorized')
    }
  },
  methods: {
    onClick () {
      if (!this.locked || this.placeholder) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fab-button {
  min-width: 160px;
  min-height: 21px;
  border: 2px solid #aaa;
  border-radius: 5px;
  background: #fff;
  padding: 0 5px;
  cursor: pointer;

  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &.placeholder {
    opacity: 0.2;
    pointer-events: none;
  }

  &.expanded {
    border-color: #94d0de;
    background-color: #d0f5f6;
  }

  &.locked {
    background: #ccc;
    disabled: disabled;
    cursor: not-allowed;

    .fa-lock {
      color: rgb(227, 136, 9);
    }
  }

  &:hover {
    border-color: #bbb;
    background: #eee;

    &.expanded {
      border-color: #7cbcc9;
      background-color: #bee6e7;
    }

    &.locked {
      border-color: rgb(227, 136, 9);
      background: #ccc;
    }
  }
}
</style>
