<template lang="html">
  <MapActionControl />
</template>

<script>
import OverlayMixin from '@components/map/mixins/OverlayMixin'
import MapActionControl from '@frs/components/map/plugins/fab/MapActionControl'

export default {
  components: {
    MapActionControl
  },
  mixins: [
    OverlayMixin
  ],
  data () {
    return {
      stopEvent: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
