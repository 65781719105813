<template lang="html">
  <FrsFabOverlay v-if="position" :position="position" centered />
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {transform} from 'ol/proj'
import {getCenter} from 'ol/extent'

import {geoJsonFormat} from '@helpers/openlayers/features'

import FrsFabOverlay from '@frs/components/map/FrsFabOverlay'

export default {
  components: {
    FrsFabOverlay
  },
  computed: {
    ...mapState('fieldRecordSystem/navigation', [
      'simplifiedGeometries'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    position () {
      if (!this.fieldId) return null

      const geoJson = this.simplifiedGeometries[this.fieldId]
      if (!geoJson) return null

      const geometry = geoJsonFormat.readGeometry(geoJson)
      const center = getCenter(geometry.getExtent())
      return transform(center, 'EPSG:3857', 'EPSG:4326')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
